<div class="d-flex flex-column h-100">
  <div class="d-flex justify-content-between align-items-center">
    @if (!responsiveUiService.isMobileDevice()) {
      <h4>
        {{
          channelId
            ? ('DEVICES_CONNECTED_TO_THIS_CHANNEL' | translate)
            : ('DEVICES_CONNECTED_TO_THIS_PROFILE' | translate)
        }}
      </h4>
    }
    @if (canVerifyHash && !buttonsHidden) {
      <button class="btn btn-outline-primary" (click)="provision()">
        {{ 'PROVISION_DEVICE' | translate }}
      </button>
    }
  </div>
  <designage-data-table
    configId="device-list"
    [data]="locationId ? devicesStore.devicesByLocation(locationId) : devices()"
    [columns]="desColumns"
    [showBulkActions]="showBulkAction"
    [loading]="loading()"
    [customComponent]="[
      screenPower,
      screenVolume,
      deviceTime,
      deviceTimeAgo,
      rebootTime,
      memory,
    ]"
    [selectedRows]="selectedRows()"
    (selectedRowsChange)="selectedRows.set($event)"
    [alwaysSort]="true"
    (rowClick)="onRowClick($event)"
    [showMultiSelect]="
      enableCheckbox && !(enableSlidePanel && isSlidePanelOpen())
    "
    [rowActiveInSlidingPanel]="selectedDeviceId()"
  >
    <div footerTemplate>
      <div class="px-2 badge bg-success mx-2">
        {{ 'ONLINE' | translate }}:
        <span class="fs-6 fw-bold">
          {{ onlineDevices().length }}
        </span>
      </div>
      <div class="px-2 badge bg-danger">
        {{ 'OFFLINE' | translate }}:
        <span class="fs-6 fw-bold">
          {{ offlineDevices().length }}
        </span>
      </div>
    </div>
    <!-- Bulk Actions Template -->
    <ng-container ngProjectAs="bulkActions">
      @for (action of bulkActions; track action) {
        <button
          class="my-1 btn btn-sm mx-2"
          (click)="bulkActionClick(action)"
          [ngClass]="bulkActionsUtil.getButtonClass(action).class"
          [ngbTooltip]="
            bulkActionsUtil.getButtonClass(action).toolTip ?? '' | translate
          "
        >
          {{ action | translate }}
        </button>
      }
    </ng-container>
  </designage-data-table>
  <ng-template #screenPower let-row>
    @if (row.deviceInfo) {
      <app-device-brightness [deviceInfo]="row.deviceInfo" />
    } @else {
      -
    }
  </ng-template>
  <ng-template #memory let-row>
    @if (row.deviceInfo) {
      <app-device-memory [deviceInfo]="row.deviceInfo" [compressed]="true" />
    } @else {
      -
    }
  </ng-template>
  <ng-template #screenVolume let-row>
    @if (row.deviceInfo) {
      <app-device-volume [deviceInfo]="row.deviceInfo" />
    } @else {
      -
    }
  </ng-template>
  <ng-template #rebootTime let-row>
    @if (row.advancedSettings) {
      <app-device-reboot-time [advancedSettings]="row.advancedSettings" />
    } @else {
      -
    }
  </ng-template>
  <ng-template #deviceTime let-row>
    @if (row.deviceInfo?.currentTime?.currentDate) {
      <table-dateTime
        [data]="row.deviceInfo?.currentTime?.currentDate"
        [timezoneOffset]="row.timezoneOffset"
      />
    } @else {
      -
    }
  </ng-template>
  <ng-template #deviceTimeAgo let-row>
    <table-dateTime
      [data]="row.lastPing"
      [pipeType]="'amTimeAgo'"
      [tooltipPipeArgs]="'medium'"
    />
  </ng-template>
  @if (!loading() && devices().length === 0) {
    <div class="col-12">
      <hr />
      <h4 class="unavailable-message">{{ 'NO_DEVICES_FOUND' | translate }}</h4>
    </div>
  }
</div>

<!-- Device Manage Slide Panel -->
@if (enableSlidePanel) {
  <router-outlet />
}
