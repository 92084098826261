import {
  ChangeDetectionStrategy,
  Component,
  OnInit,
  signal,
} from '@angular/core';
import { ChannelBulkAction, DeviceBulkAction } from '@designage/gql';
import { FormDialogComponent } from '@desquare/components/common/src/modals/form-dialog.component';
import { CurrentUserService } from '@desquare/services';
import { DeviceData } from '@desquare/types';
import { TranslateModule } from '@ngx-translate/core';

@Component({
  standalone: true,
  selector: 'app-device-bulk-action-dialog',
  imports: [FormDialogComponent, TranslateModule],
  template: `
    <app-form-dialog
      closeButtonText="{{ action | translate }}"
      dismissButtonText="CANCEL"
      headerText="{{ action | translate }}"
      [valid]="devices.length > 0"
    >
      <form id="publishForm">
        <div class="row">
          @if (showAppletType) {
            <div class="d-flex align-items-baseline mb-3">
              <div
                class="badge"
                [class.bg-success]="!isSmilOn"
                [class.bg-danger]="isSmilOn"
              >
                {{ 'CLASSIC' | translate }}
              </div>
              <span class="mx-2 form-check form-switch">
                <input
                  type="checkbox"
                  class="form-check-input"
                  id="toggleSmilCheckbox"
                  [checked]="isSmilOn"
                  (click)="toggleSmil()"
                />
                <label
                  class="form-check-label"
                  for="toggleSmilCheckbox"
                ></label>
              </span>
              <div
                class="badge"
                [class.bg-success]="isSmilOn"
                [class.bg-danger]="!isSmilOn"
              >
                {{ 'SMIL' | translate }}
              </div>
            </div>
          }

          <div class="col-12 form-group">
            @if (devices.length > 0) {
              <div>
                <p>{{ 'DEVICE_ACTION_APPLICABLE' | translate }}</p>
                <ul>
                  @for (device of devices; track device.id) {
                    <li>{{ device.name }}</li>
                  }
                </ul>
              </div>
            } @else {
              <div>
                <p>{{ 'DEVICE_ACTION_NOT_APPLICABLE' | translate }}</p>
              </div>
            }
          </div>
        </div>
      </form>
    </app-form-dialog>
  `,
})
export class DeviceBulkActionDialogComponent implements OnInit {
  devices: DeviceData[] = [];
  action!: DeviceBulkAction;

  extra: string = 'CLASSIC';
  isSmilOn: boolean = false;

  get showAppletType() {
    return (
      this.action === DeviceBulkAction.DeviceAppletUpdateVersion &&
      this.currentUserService.isSuperAdmin
    );
  }

  constructor(private currentUserService: CurrentUserService) {}

  ngOnInit(): void {
    console.log('bulk action', this.action);
  }

  toggleSmil() {
    this.isSmilOn = !this.isSmilOn;
    this.extra = this.isSmilOn ? 'SMIL' : 'CLASSIC';
  }
}
